import React from "react"
import { navigate } from "gatsby"
import SEO from "../components/SEO"
import { Pagination } from "../components/ui"
import { groupBy, prop, sort, flatten } from "ramda"
import Footer from "../components/footer"
import { VisitPageForm, VisitFormButton } from "../components/form-biz"
const loadText = require("src/utils").loadText
const static_data = loadText("event")
import "../styles/blog.less"
import { getStatus } from "./event-singular"
import { SolutionTopBanner, EventCard } from "../components/block"
const Content = ({ events }) => {
  return (
    <div className="blog-content">
      <div className="block-middle">
        <div className="blog-content-wrapper page-middle">
          {events.map(event => (
            <EventCard {...event} />
          ))}
        </div>
      </div>
    </div>
  )
}
const Blog = ({ pageContext }) => {
  const { pageNum, pageTotal, prefixSlug, totalData, pageSize } = pageContext
  const totalDataTransMap = groupBy(
    prop("status"),
    (totalData || []).map(d => ({
      ...d,
      status: getStatus(d.startTime, d.endTime, d.webinar_link),
    }))
  )
  const startIdx = (pageNum - 1) * 9
  const totalDataSorted = flatten(
    ["webinar", "upcoming", "ongoing", "finished"].map(
      status => totalDataTransMap[status] || []
    )
  ).slice(startIdx, 9 + startIdx)
  const data = totalDataSorted
  console.log(data)
  return (
    <div className="blog event">
      <SEO {...static_data.seo}>
        {pageNum !== 1 && (
          <link
            rel="prev"
            href={`${process.env.GATSBY_HOST}${prefixSlug}/${pageNum - 1}`}
          />
        )}
        {pageNum !== pageTotal && (
          <link
            rel="next"
            href={`${process.env.GATSBY_HOST}${prefixSlug}/${pageNum + 1}`}
          />
        )}
      </SEO>
      <SolutionTopBanner
        {...static_data.topBanner}
        bgPic={"/event/topBanner-bg.svg"}
        pic={{
          src: "/event/topBanner-pic.png",
          style: {
            position: "absolute",
            bottom: "-40px",
            right: "120px",
            width: "630px",
          },
        }}
        bannerPic={{
          src: `/event/topBanner-bg-m.svg`,
          style: {
            backgroundPosition: "top center",
          },
        }}
        button={VisitFormButton}
        source="event"
        height={480}
        bgColor="linear-gradient(180deg,rgba(235,251,254,1) 0%,rgba(230,246,249,1) 49%,rgba(190,242,250,1) 100%)"
      />
      <Content events={data} />
      {pageTotal > 9 && (
        <div className="blog-pagination">
          <Pagination
            pageNum={pageNum}
            total={pageTotal}
            pageSize={pageSize}
            onChange={page => {
              navigate(`${prefixSlug}/${page}`)
            }}
          />
        </div>
      )}
      <VisitPageForm {...static_data.form} source="event" />
      {/* <BottomBanner /> */}
      <Footer />
    </div>
  )
}

export default Blog
